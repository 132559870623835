define("discourse/plugins/discourse-calendar/discourse/connectors/before-topic-list-body/category-calendar", ["exports", "@glimmer/component", "@ember/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CategoryCalendar extends _component.default {
    static shouldRender(_1, ctx1) {
      return ctx1.siteSettings.calendar_categories_outlet === "before-topic-list-body";
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="before-topic-list-body-outlet category-calendar"></div>
      
    */
    {
      "id": "Qr4ms8vc",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"before-topic-list-body-outlet category-calendar\"],[12],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-calendar/discourse/connectors/before-topic-list-body/category-calendar.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CategoryCalendar;
});